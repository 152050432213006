<template>
	<div>
		<div class="row mt-4 mb-4">
			<div class="col-sm-12 text-right">
				<h1>Accesos a expedientes</h1>
			</div>
		</div>

		<DataGrid :config="dataGridConfig" :data="accesos" :select="seleccionar" @actions="dataGridActions">
			<div class="row">
				<div class="col-sm-2">
					<button class="btn warning-btn" @click="obtener_accesos">Recargar</button>
				</div>
			</div>
		</DataGrid>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV2'

	import api from '@/apps/clientes/api/clientes'
	
	export default {
		components: {
			DataGrid
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'accesos'
					,cols: {
						nombre_cliente: 'Cliente'
						,nombre_usuario: 'Usuario'
						,razones: 'Razón'
						,created_at: 'Fecha de ingreso'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						created_at: function(val,row,vue) {
							return vue.$moment(val).format('YYYY-MM-DD hh:mm:ss')
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionadas: []
				,seleccionar: false
				,accesos: []
			}
		}
		,methods: {
			obtener_accesos: async function() {
				let res = (await api.listar_accesos(this.options)).data;

				this.accesos = res.data;
				this.dataGridConfig.paginator.pagina_actual = res.current_page;
				this.dataGridConfig.paginator.total_registros = res.total;
				this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
			}
			,dataGridActions: function(tipo, data) {
				this.$log.info('tipo', tipo);
				if (tipo == 'options') {
					this.options = data;
					this.obtener_accesos();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
		}
	}
</script>